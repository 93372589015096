<template>
  <span>
    <wx-open-data
      :type="2"
      :id="departName || '未设置'"
      :nameData="{name:departName || '未设置'}"
      v-if="!$isDD"
    />

    <el-tooltip placement="top" v-else>
      <div slot="content" v-html="`${departNameAllSplit()}`"></div>
      <span class="user-list-wrap">
        <span v-for="(departItem, index) in departUserList" :key="departItem.id">
          <wx-open-data
            :type="2"
            :id="departItem.departName"
            :nameData="{name:departItem.departName}"
          />
          {{index !== departUserList.length - 1 ? ';': ''}}
        </span>
        <span class="user-list-wrap" v-if="departUserList.length === 0">未设置</span>
      </span>
    </el-tooltip>
  </span>
</template>
<script>
export default {
  props: {
    departName: {
      default: ''
    },
    departUserList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {
    departNameAllSplit(departName) {
      var departNameAll = '未设置'
      if (this.departUserList.length > 0) {
        departNameAll = ''
        this.departUserList.forEach((item, index) => {
          departNameAll += `${this.$isQw ? `<ww-open-data type="departmentName" openid="${item.departName}"></ww-open-data>`: `${item.departName}`} ${
            index < this.departUserList.length - 1 ? ';' : ''
          }`
        })
      } else if (this.departName) {
        departNameAll = `${this.$isQw ? `<ww-open-data type="departmentName" openid="${this.departName}"></ww-open-data>` : `${this.departName}`}`
      }
      return departNameAll 
    },
  }
}
</script>
<style scoped lang="less">
.user-list-wrap {
  .text-n(1);
}
</style>