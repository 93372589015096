const versionNumber = 'v1' // 版本
const versionNumber2 = 'v2' // 版本
const versionNumber3 = 'v3' // 版本
// 企信迁移后新增
// OKR模块api
export default [{
    // okr自动化推进设置回显
    okrGetAutoProgress: `/api/ranloWeb/${versionNumber}/keyresults/progress/automation/echo`
  },
  {
    // OKR自动化推进设置保存
    okrtAutoProgressSave: `/api/ranloWeb/${versionNumber}/keyresults/progress/automation/save`
  },
  {
    // OKR自动化任务列表
    okrtAutoTaskList: `/api/ranloTask/${versionNumber}/task/getAllTaskListByKeyIdE`
  },
  {
    // 某人的目标列表
    okrListByPerson: `/api/ranloWeb/${versionNumber2}/objectives/listing/`
  },
  {
    // 要对齐的目标列表
    okrToAlignList: `/api/ranloWeb/${versionNumber2}/objectivescollaborator/cycle/`
  },
  {
    // 更新邀请对齐已读状态
    okrUpdateAlignState: `/api/ranloWeb/${versionNumber}/objectivescollaborator/update/status`
  },
  {
    // 删除邀请对齐
    okrDeleteAlign: `/api/ranloWeb/${versionNumber}/objectivescollaborator/delete/`
  },
  {
    // 对齐的目标信息
    okrAlignObjInfo: `/api/ranloWeb/${versionNumber2}/objectivescollaborator/info`
  },
  {
    // 单个目标信息
    okrObjInfo: `/api/ranloWeb/${versionNumber3}/objectives/`
  },
  {
    // 对齐此目标的目标列表-目标子级
    okrAlignObjListing: `/api/ranloWeb/${versionNumber3}/objectives/listing/`
  },
  {
    // OKR自动化目标列表
    okrAutoOkrList: `/api/ranloWeb/${versionNumber}/objectives/listing/`
  },
  {
    // 添加对齐
    okrAddFatherAlign: `/api/ranloWeb/${versionNumber}/objectives/association/save`
  },
  {
    // 取消对齐
    okrDeleteFatherAlign: `/api/ranloWeb/${versionNumber}/objectives/association/delete`
  },
  {
    // 删除OKR
    okrDelete: `/api/ranloWeb/${versionNumber}/objectives/delete/`
  },
  {
    // 添加OKR
    okrAdd: `/api/ranloWeb/${versionNumber2}/objectives/save`
  },
  {
    // 更改OKR标题
    okrUpdateTitle: `/api/ranloWeb/${versionNumber}/objectives/update`
  },
  {
    // 更改OKR信心值
    okrConfidenceUpdate: `/api/ranloWeb/${versionNumber}/objectives/update/confidence`
  },
  {
    // 修改OKR公开状态
    okrPublicStateChange: `/api/ranloWeb/${versionNumber}/objectives/update/isShare`
  },
  {
    // 根据关键结果查询任务
    okrQueryTaskBykeyId: `/api/ranloTask/${versionNumber}/task/getTaskListByKeyId`
  },
  {
    // 根据关键结果查询任务（新）
    okrQueryTaskBykeyIdNew: `/api/ranloTask/${versionNumber}/task/getTaskListByKeyIdE`
  },
  {
    // 根据关键结果查询任务（新）
    okrQueryTaskBykeyIdNewV2: `/api/ranloTask/${versionNumber2}/task/getTaskListByKeyIdE`
  },
  {
    // 点赞OKR
    okrAddLaud: `/api/ranloWeb/${versionNumber}/objectiveslaud/save`
  },
  {
    // 取消点赞
    okrDeleteLaud: `/api/ranloWeb/${versionNumber}/objectiveslaud/delete/`
  },
  {
    // 获取OKR动态列表
    okrDynamicList: `/api/ranloWeb/${versionNumber2}/objectivesdynamic/list/page`
  },
  {
    // 获取OKR进度列表
    okrProcessList: `/api/ranloWeb/${versionNumber}/keyresultsprogress/list`
  },
  {
    // 更新OKR进度
    okrAddProcess: `/api/ranloWeb/${versionNumber}/keyresults/update/quantification/actual/value`
  },
  {
    // OKR进度中发布评论
    okrAddProcessComment: `/api/ranloWeb/${versionNumber}/keyresultsProgressComment/save`
  },
  {
    // OKR动态中发布评论
    okrAddDynamicComment: `/api/ranloWeb/${versionNumber}/objectivesdynamic/save`
  },
  {
    // 删除OKR动态评论
    okrDelDynamicComment: `/api/ranloWeb/${versionNumber}/objectivesdynamic/delete/`
  },
  {
    // OKR动态回复评论
    okrAddDynamicReply: `/api/ranloWeb/${versionNumber}/objectivesdynamic/answer`
  },
  {
    // 添加Kr
    okrAddKr: `/api/ranloWeb/${versionNumber}/keyresults/save`
  },
  {
    // 删除Kr
    okrDeleteKr: `/api/ranloWeb/${versionNumber}/keyresults/delete/`
  },
  {
    // 更新KR权重
    okrUpdateWeight: `/api/ranloWeb/${versionNumber}/keyresults/update/weight`
  },
  {
    // 更新KR排序
    okrUpdateSort: `/api/ranloWeb/${versionNumber}/keyresults/update/sort`
  },
  {
    // 更新KR标题
    okrUpdateKrTitle: `/api/ranloWeb/${versionNumber}/keyresults/update`
  },
  {
    // 更改KR量化条件
    okrUpdateKrQuantity: `/api/ranloWeb/${versionNumber}/keyresults/update/quantification`
  },
  {
    // 获取kr量化单位列表
    okrQuantityUnits: `/api/ranloWeb/${versionNumber}/dictionary/list/quantification/unit`
  },
  {
    // 获取OKR指引目标
    okrGuideTempletes: `/api/ranloWeb/${versionNumber}/dictionary/obj/template/`
  },
  {
    // 查询目标查看记录
    okrViewRecord: `/api/ranloWeb/${versionNumber}/objectives/look/recording/list`
  },
  {
    // 查询最近使用的kr
    okrRecentKrList: `/api/ranloWeb/${versionNumber}/keyresults/usageTime/list`
  },
  {
    // 查询推荐的kr列表
    okrRecommendKrList: `/api/ranloWeb/${versionNumber}/keyresults/currentCycleTime/list`
  },
  {
    // 获取单个目标的KR列表
    okrKrListByObjId: `/api/ranloWeb/${versionNumber}/objectives/simple/`
  },
  {
    // 查询当前登陆人所在的公司的目标列表【当前所有进行的周期、当前周期的上一个周期、下一个周期-总结中使用】
    okrConclusionOkrList: `/api/ranloWeb/${versionNumber}/objectives/link/up/list`
  },
  {
    // 根据关键结果查询进度列表
    okrProcessListBykr: `/api/ranloWeb/${versionNumber}/keyresultsprogress/list/key`
  },
  {
    // 地图页目标列表
    okrMapPageList: `/api/ranloWeb/${versionNumber2}/objectives/list`
  },
  {
    // 地图-根据菜单条件，查询目标列表
    objectivesMapConditionMenu: `/api/ranloWeb/${versionNumber}/objectives/map/condition/menu`
  },
  {
    // 获取目标子集列表-地图页
    okrChildAlignList: `/api/ranloWeb/${versionNumber2}/objectives/list/child/align/`
  },
  {
    // 获取对齐目标
    // getOkrAlignList: `/api/ranloWeb/${versionNumber}/objectives/v2/list/search/align`
    getOkrAlignList: `/api/ranloWeb/${versionNumber}/objectives/search/align`
  },
  {
    // 地图评论删除
    okrMapCommentDel: `/api/ranloWeb/${versionNumber}/objectivesdynamic/delete/`
  },
  {
    // 地图评论列表
    okrMapCommentList: `/api/ranloWeb/${versionNumber2}/objectivesdynamic/list/page`
  },
  {
    // 提醒他
    okrMapRemind: `/api/ranloWeb/${versionNumber}/objectives/collaborator/notify`
  },
  {
    // 复制目标
    okrCopy: `/api/ranloWeb/${versionNumber}/objectives/copy`
  },
  {
    // 移动目标
    okrMove: `/api/ranloWeb/${versionNumber}/objectives/move`
  },
  {
    // 转让目标
    okrTransfer: `/api/ranloWeb/${versionNumber}/objectives/transfer`
  },
  {
    // 获取Kr已关联的项目列表
    krAttachedProjects: `/api/ranloWeb/${versionNumber}/keyresults/project/list`
  },
  {
    // 获取Kr可关联的项目列表
    krAttachAllProjects: `/api/ranloTask/${versionNumber}/project/getUserProjectList`
  },
  {
    // kr关联项目
    krAddAttach: `/api/ranloWeb/${versionNumber}/keyresults/project`
  },
  {
    // 校验当前成员是否在项目成员里
    projectCheck: `/api/project/${versionNumber2}/project/check/`
  },
  {
    // kr取消关联项目
    krDelAttach: `/api/ranloWeb/${versionNumber}/keyresults/project/`
  },
  {
    // 左侧评分按钮弹框是否显示
    targetCycledynamic: `/api/ranloWeb/${versionNumber}/cycledynamic/select/new`
  },
  {
    // 查询企业内OKR(缺省页)
    okrCompanyDefaultList: `/api/ranloWeb/${versionNumber}/objectives/company/cycle/obj/list`
  },
  {
    // 提交查看记录
    okrViewRecordSave: `/api/ranloWeb/${versionNumber}/objectives/look/recording/save`
  },
  {
    // 批量提交查看记录
    okrViewRecordSaveBatch: `/api/ranloWeb/${versionNumber}/objectives/look/recording/save/batch`
  },
  {
    // 获取目标建议字典
    okrWriteRules: `/api/ranloWeb/${versionNumber}/dictionary/write/rule`
  },
  {
    // 忽略目标建议
    okrIgnoreWriteRule: `/api/ranloWeb/${versionNumber}/objectives/write/rule/ignore`
  },
  {
    // okr文件列表
    okrFileList: `/api/ranloWeb/${versionNumber}/objectives/resource`
  },
  {
    // okr文件置顶/取消置顶
    okrFileTop: `/api/ranloWeb/${versionNumber}/objectives/resource/type`
  },
  {
    // okr文件状态设置
    okrFileStatus: `/api/ranloWeb/${versionNumber}/objectives/resource/status`
  },
  {
    // okr文件删除(单个or批量)
    deleteOkrFile: `/api/ranloWeb/${versionNumber}/objectives/resource/delete/`
  },
  {
    // okr单个文件删除(
    deleteOkrFileOne: `/api/oss/${versionNumber}/delete/file`
  },
  {
    // okr文件保存
    okrFilesSave: `/api/ranloWeb/${versionNumber}/objectives/resource`
  },
  {
    // 目标权限修改
    okrPowerUpdate: `/api/ranloWeb/${versionNumber}/objectives/power/update`
  },
  {
    // 查询目标查看权限
    okrPowerDetail: `/api/ranloWeb/${versionNumber}/objectives/power/`
  },
  {
    // 关联自动化推进显示（小图标显示）
    okrAutoProgressList: `/api/ranloWeb/${versionNumber2}/keyresults/progress/automation/relation`
  },
  {
    // 添加OKR评分
    updateKrScore: `/api/ranloWeb/${versionNumber}/keyresults/update/score`
  },
  {
    // okr共享（添加/修改共享人）
    changeOkrShare: `/api/ranloWeb/${versionNumber}/objectives/shared/user/save`
  },
  {
    // okr共享列表
    okrShareList: `/api/ranloWeb/${versionNumber}/objectives/shared/user/`
  },
  {
    // 查询目标共享人动态列表
    okrShareDynamic: `/api/ranloWeb/${versionNumber}/objectives/shared/user/dynamic/list`
  },
  {
    // 修改目标标签
    okrUpdateLabel: `/api/ranloWeb/${versionNumber}/objectives/update/label`
  },
  {
    // 根据目标id,获取关键结果列表
    okrIdKeyList: `/api/ranloWeb/${versionNumber}/keyresults/list`
  },
  {
    // 目标地图向上
    okrAlignObjListingUp: `/api/ranloWeb/${versionNumber}/objectives/list/father/align`
  },
  {
    // 查询目标列表-地图-v3
    // okrObjectivesList: `/api/ranloWeb/${versionNumber3}/objectives/list`
    okrObjectivesList: `/api/ranloWeb/${versionNumber}/objectives/new/map/condition/menu`
  },
  {
    // 查询向下对齐目标信息和邀请未对齐信息-v3
    okrObjectivesListChild: `/api/ranloWeb/${versionNumber3}/objectives/list/child/align`
  },
  {
    // kr智能推进分配额度提醒
    okrKrProgressValue: `/api/ranloWeb/${versionNumber}/keyresults/distribution`
  },
  {
    // 根据周期id,查询创建人和共享人是自己的目标列表
    okrHaveSharedList: `/api/ranloWeb/${versionNumber}/objectives/have/shared/list`
  },
  {
    // 查询菜单列表
    conditionMenuList: `/api/ranloWeb/${versionNumber}/condition/menu/list`
  },
  {
    // 添加关注
    companyUserCareSave: `/api/ranloWeb/${versionNumber}/company/user/care/save`
  },
  {
    // 取消关注
    companyUserCareDelete: `/api/ranloWeb/${versionNumber}/company/user/care/delete`
  },
  {
    // 查询关注列表
    companyUserCareList: `/api/ranloWeb/${versionNumber}/company/user/care/list`
  },
  {
    // 查询全部菜单列表
    conditionMenuListAll: `/api/ranloWeb/${versionNumber}/condition/menu/list/all`
  },
  {
    // 批量修改菜单
    conditionMenuUpdateBatch: `/api/ranloWeb/${versionNumber}/condition/menu/update/batch`
  },
  {
    // 查询默认菜单列表
    conditionMenuListDefault: `/api/ranloWeb/${versionNumber}/condition/menu/list/default`
  },
  {
    // 列表-根据菜单条件，查询目标列表
    objectivesListConditionMenu: `/api/ranloWeb/${versionNumber2}/objectives/list/condition/menu`
  },
  {
    // kr进度评论删除
    krProgressCommentDelete: `/api/ranloWeb/${versionNumber}/keyresultsProgressComment/delete/`
  },
  {
    // 列表-根据菜单条件,查询目标列表-导出
    exportConditionMenu: `/api/ranloWeb/${versionNumber}/objectives/export/condition/menu`
  },
  {
    // 关注目标
    okrCareConfirm: `/api/ranloWeb/${versionNumber}/objectives/care`
  },
  {
    // 取消关注目标
    okrCareCancel: `/api/ranloWeb/${versionNumber}/objectives/unCare`
  },
  {
    // 查询目标列表 表头
    okrTableTitle: `/api/ranloWeb/${versionNumber}/objectives/list/title/list`
  },
  {
    // 列表-根据菜单条件，查询目标列表-表格
    okrTableMenuList: `/api/ranloWeb/${versionNumber}/objectives/excel/condition/menu`
  },
  {
    // 修改目标列表表头是否展示
    okrTableMenuTitleShow: `/api/ranloWeb/${versionNumber}/objectives/list/title/show`
  },
  {
    // 修改目标列表表头是否展示
    okrTableMenuTitleOrder: `/api/ranloWeb/${versionNumber}/objectives/list/title/order`
  },
  {
    // 根据人员id,查询向上对齐关系的目标列表
    getFatherAlignObjList: `/api/ranloWeb/${versionNumber}/objectives/list/father/align/user`
  },
  {
    // 根据人员id,查询向下对齐关系的目标列表
    getChildAlignObjList: `/api/ranloWeb/${versionNumber}/objectives/list/child/align/user`
  },
  {
    // 根据kr或者目标标题 搜索 目标
    okrSearchTitleList: `/api/ranloWeb/${versionNumber}/objectives/keyresults/obj/title`
  },
  {
    // 审批设置详情
    approvalDetail: `/api/admin/${versionNumber}/approval/detail`
  },
  {
    // 审批设置保存或者修改
    approvalUpdate: `/api/admin/${versionNumber}/approval/update`
  },
  {
    // 审批流详情
    approvalProcessRecord: `/api/admin/${versionNumber2}/approval/process/record`
  },
  {
    // 分页查询 审批数据列表
    approvalPageList: `/api/ranloWeb/${versionNumber}/objectives/process/data/page/list`
  },
  {
    // 查询我的审批红点数量
    approvalRedCount: `/api/admin/${versionNumber}/approval/process/red/count`
  },
  {
    // 催办
    approvalDataRemind: `/api/ranloWeb/${versionNumber}/objectives/process/data/remind`
  },
  {
    // 提交人撤销/提交流程
    approvalDataSubmitter: `/api/ranloWeb/${versionNumber}/objectives/process/data/submitter`
  },
  {
    // 审批流程操作 审批通过/驳回
    approvalProcessOperation: `/api/admin/${versionNumber}/approval/process/operation`
  },
  {
    // 检测当前用户是否需要审批
    approvalCheck: `/api/admin/${versionNumber2}/approval/check`
  },
  {
    // 单个目标信息审批
    okrObjInfoSimple: `/api/ranloWeb/${versionNumber}/objectives/simple/`
  },
  {
    // 设置默认周期
    setCycleDefState: `/api/ranloWeb/${versionNumber}/setcycle/def/state`
  },
  {
    // 根据模板导入目标
    objectivesImportExcel: `/api/ranloWeb/${versionNumber2}/objectives/import/excel`
  },
  {
    // 根据周期id,人员等分页查询kr列表
    objectivesKeyresults: `/api/ranloWeb/${versionNumber}/keyresults/list/cycle`
  },
  {
    // 审批流程操作 审批通过/驳回 -批量
    approvalProcessApprovalBatch: `/api/admin/${versionNumber}/approval/process/approval/batch`
  }
]
