var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "menu-list-wrap",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "el-popover",
        {
          ref: "accountSetting",
          staticClass: "avatar-img avatar-popover",
          attrs: {
            placement: "bottom-end",
            width: "250",
            trigger: "click",
            "visible-arrow": false,
            "popper-options": {
              boundariesElement: "viewport",
              removeOnDestroy: true,
            },
            "popper-class": "popover-header",
          },
        },
        [
          _c("ul", { staticClass: "popover-ul" }, [
            _c("li", { staticClass: "person-info" }, [
              _c(
                "div",
                { staticClass: "person-left" },
                [
                  _c("el-image", {
                    staticClass: "border-radius-20",
                    attrs: {
                      fit: "cover",
                      src:
                        _vm.userInfo.avatar ||
                        require("@/assets/imgs/common/avatar.png"),
                      alt: "",
                    },
                  }),
                  _vm.accountRedpoint
                    ? _c("div", { staticClass: "red-point" })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "person-right" },
                [
                  _c("wx-open-data", {
                    attrs: {
                      id: _vm.userInfo.username,
                      nameData: { name: _vm.userInfo.name },
                    },
                  }),
                  _c(
                    "p",
                    { staticClass: "job-title" },
                    [
                      _c("DepartUserList", {
                        attrs: {
                          departName: _vm.userInfo.departName,
                          departUserList: _vm.userInfo.departUserList,
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.userInfo.jobTitle) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            !_vm.$isQw && !_vm.$isDD
              ? _c(
                  "div",
                  [
                    _c("li", { staticClass: "my-company" }, [
                      _vm._v(_vm._s(_vm.type === 1 ? "我的企业" : "切换企业")),
                    ]),
                    _vm._l(_vm.personCompanyList, function (item) {
                      return _c(
                        "li",
                        {
                          key: item.id,
                          staticClass: "company-list",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.focusTogether(item.id, item.name)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "company-list-left" }, [
                            _c("div", { staticClass: "logo" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    item.logo ||
                                    require("@/assets/imgs/common/avatar.png"),
                                  alt: "",
                                },
                              }),
                              _vm.type !== 2 && item.notifyNoReadCount > 0
                                ? _c("div", { staticClass: "number" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.notifyNoReadCount > 99
                                            ? "···"
                                            : item.notifyNoReadCount
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "name",
                                class: item.userState === 0 ? "name-color" : "",
                              },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "company-list-right" },
                            [
                              item.userState === 0
                                ? _c("div", [_vm._v("邀请加入")])
                                : _vm._e(),
                              item.id === _vm.companyInfo.id
                                ? _c("Icon", {
                                    staticClass: "svg-company",
                                    attrs: {
                                      "icon-class": "icon-draw_checked",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    _c(
                      "li",
                      {
                        staticClass: "company-more",
                        on: { click: _vm.switchEmpty },
                      },
                      [
                        _c("Icon", {
                          staticClass: "svg-list",
                          attrs: { "icon-class": "icon-more-row" },
                        }),
                        _vm._v("更多企业 "),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c("li", { staticClass: "header-ul-center" }, [
              _c("ul", { staticClass: "header-ul-inner" }, [
                _vm.type === 2
                  ? _c(
                      "li",
                      { staticClass: "clickDisabled" },
                      [
                        _c("Icon", {
                          staticClass: "svg-list",
                          attrs: { "icon-class": "icon-a-weibiaoti-2_huaban1" },
                        }),
                        _vm._v("账号设置 "),
                      ],
                      1
                    )
                  : _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.topMenuTab("setting")
                          },
                        },
                      },
                      [
                        _c("Icon", {
                          staticClass: "svg-list",
                          attrs: { "icon-class": "icon-a-weibiaoti-2_huaban1" },
                        }),
                        _vm._v("账号设置 "),
                      ],
                      1
                    ),
                _c(
                  "li",
                  { on: { click: _vm.feedBack } },
                  [
                    _c("Icon", {
                      staticClass: "svg-list",
                      attrs: { "icon-class": "icon-jiaruqiyehoutai" },
                    }),
                    _vm._v("意见反馈 "),
                  ],
                  1
                ),
                _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.topMenuTab("/helpH5/contactUs")
                      },
                    },
                  },
                  [
                    _c("Icon", {
                      staticClass: "svg-list",
                      attrs: { "icon-class": "icon-kefu" },
                    }),
                    _vm._v("联系客服 "),
                  ],
                  1
                ),
                _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.topMenuTab("help")
                      },
                    },
                  },
                  [
                    _c("Icon", {
                      staticClass: "svg-list",
                      attrs: { "icon-class": "icon-bangzhu" },
                    }),
                    _vm._v("帮助和常见问题 "),
                  ],
                  1
                ),
                _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.topMenuTab("helpCenter")
                      },
                    },
                  },
                  [
                    _c("Icon", {
                      staticClass: "svg-list",
                      attrs: { "icon-class": "icon-xuexizhongxin" },
                    }),
                    _vm._v("学习中心 "),
                  ],
                  1
                ),
                _vm.userInfo.isSuperAdmin == 1 && _vm.type === 1
                  ? _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.topMenuTab("admin")
                          },
                        },
                      },
                      [
                        _c("Icon", {
                          staticClass: "svg-list",
                          attrs: { "icon-class": "icon-jiaruqiyehoutai" },
                        }),
                        _vm._v("进入企业后台 "),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.userInfo.isSuperAdmin == 1 && _vm.type === 2
                  ? _c(
                      "li",
                      { staticClass: "clickDisabled" },
                      [
                        _c("Icon", {
                          staticClass: "svg-list",
                          attrs: { "icon-class": "icon-jiaruqiyehoutai" },
                        }),
                        _vm._v("进入企业后台 "),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            !_vm.$isQw && !_vm.$isDD
              ? _c(
                  "li",
                  { staticClass: "header-exit", on: { click: _vm.exitBtn } },
                  [
                    _c("Icon", {
                      staticClass: "svg-list",
                      attrs: { "icon-class": "icon-tuichudenglu" },
                    }),
                    _vm._v("退出登录 "),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "image-wrap",
              attrs: { slot: "reference" },
              on: { click: _vm.popoverClick },
              slot: "reference",
            },
            [
              _c("el-image", {
                staticClass: "avatar-img border-radius-20",
                attrs: {
                  src:
                    _vm.userInfo.avatar ||
                    require("@/assets/imgs/common/avatar.png"),
                  fit: "cover",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.feedBackState
        ? _c("free-back", {
            attrs: { visible: _vm.feedBackState },
            on: {
              "update:visible": function ($event) {
                _vm.feedBackState = $event
              },
              closeFreeBack: _vm.closeFreeBack,
            },
          })
        : _vm._e(),
      _vm.switch_enterprises && !_vm.$isQw && !_vm.$isDD
        ? _c("switch-enterprises", {
            attrs: { visible: _vm.switch_enterprises },
            on: {
              "update:visible": function ($event) {
                _vm.switch_enterprises = $event
              },
              closeSwitchCompany: _vm.closeSwitchCompany,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }