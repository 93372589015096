var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      !_vm.$isDD
        ? _c("wx-open-data", {
            attrs: {
              type: 2,
              id: _vm.departName || "未设置",
              nameData: { name: _vm.departName || "未设置" },
            },
          })
        : _c("el-tooltip", { attrs: { placement: "top" } }, [
            _c("div", {
              attrs: { slot: "content" },
              domProps: { innerHTML: _vm._s(`${_vm.departNameAllSplit()}`) },
              slot: "content",
            }),
            _c(
              "span",
              { staticClass: "user-list-wrap" },
              [
                _vm._l(_vm.departUserList, function (departItem, index) {
                  return _c(
                    "span",
                    { key: departItem.id },
                    [
                      _c("wx-open-data", {
                        attrs: {
                          type: 2,
                          id: departItem.departName,
                          nameData: { name: departItem.departName },
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            index !== _vm.departUserList.length - 1 ? ";" : ""
                          ) +
                          " "
                      ),
                    ],
                    1
                  )
                }),
                _vm.departUserList.length === 0
                  ? _c("span", { staticClass: "user-list-wrap" }, [
                      _vm._v("未设置"),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }