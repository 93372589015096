var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticStyle: { display: "inline" } },
    [
      _vm.$isQw
        ? _c("ww-open-data", {
            attrs: {
              type: _vm.type == 1 ? "userName" : "departmentName",
              openid: _vm.id,
            },
          })
        : [_vm._v(_vm._s(_vm.nameData && _vm.nameData.name))],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }