<template>
  <div>
    <el-popover
      ref="userPickerPop"
      popper-class="user-picker-atdialog-pop"
      :visible-arrow="false"
      placement="bottom-start"
      :disabled="disabled"
      @show="init(1)"
    >
      <div class="user-picker-out">
        <div
          class="search-box"
          v-if="!taskId && pageType !== 'workHours' && pageType !== 'pmAdminUser' && pageType !== 'adminUser' && pageType !== 'analyseDepartUser'"
        >
          <el-input
            v-model="name"
            prefix-icon="el-icon-search"
            placeholder="搜索成员"
            class="user-el-input"
            @input="input"
          />
        </div>
        <div class="people-title">成员{{ total }}</div>
        <div class="list-box">
          <div class="list-box-wrap" v-scroll-bottom="$loadMoreList">
            <div v-for="item in list" :key="item && item.id" class="list-item" @click="pick(item)">
              <el-image
                :src="item.avatar || require('@/assets/imgs/common/avatar.png')"
                class="avatar-img border-radius-20"
                fit="cover"
              />
              <div class="name-wrap">
                <div class="p-l-10">
                  <wx-open-data :id="item.username" :nameData="{name:item.name}" />
                </div>
                <div class="departName" v-if="item.departName || item.jobTitle">
                  <span>
                    <DepartUserList
                      :departName="item.departName"
                      :departUserList="item.departUserList"
                    ></DepartUserList>
                  </span>
                  {{item.jobTitle}}
                </div>
              </div>
            </div>
          </div>
          <div
            class="list-item peopeAdd"
            @click.stop="departAdd()"
            v-if="pageType !== 'workHours' && pageType !== 'pmAdminUser' && pageType !== 'adminUser' && pageType !== 'analyseDepartUser'"
          >
            <Icon icon-class="icon-a-tianjia2" class="avatar-img" />
            <div class="p-l-10">按组织选择</div>
          </div>
        </div>
      </div>
      <slot slot="reference"></slot>
    </el-popover>
    <invite-by-code v-if="closeInviterCodeBoolean === true" @closeInviterCode="closeInviterCode" />
    <depart-picker
      v-if="departUserVisible"
      v-model="departUserVisible"
      type="person"
      :canDelete="true"
      :multipleChoice="false"
      :isIncludeMe="pageType == 'taskSummary' ? 1 : 0"
      :userScope="userScope"
      @saveResult="chargeUserPicker"
    />
  </div>
</template>

<script>
import debounce from 'lodash/debounce' // 局部引入防抖
export default {
  props: {
    subVisible: {
      type: String,
      required: false
    },
    pageType: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean
    },
    taskId: {
      type: String,
      default: ''
    },
    projectId: {},
    departListId: {
      type: Object,
      default: () => {
        return []
      }
    },
    userScope: { // -1:过滤非正常人
      default: ''
    }
  },
  data() {
    return {
      userList: [],
      list: [],
      name: null, // 员工姓名
      pageNum: 1,
      pageSize: 10,
      total: 0,
      getDataState: true,
      departUserVisible: false,
      businessScope: 1
    }
  },
  created() {
    // 初始化
    // this.init()
  },
  methods: {
    input: debounce(function () {
      this.businessScope = 0
      this.init(1)
    }, 200),
    // 获取企业成员列表
    $loadMoreList() {
      if (
        parseInt(this.list.length) < parseInt(this.total) &&
        this.getDataState &&
        this.pageType !== 'workHours' && this.pageType !== 'adminUser' && this.pageType !== 'pmAdminUser'
      ) {
        this.init()
        // this.getDataState = false
      }
    },
    init(type) {
      if (this.taskId) {
        this.init2()
        return
      }
      if (this.pageType === 'adminUser') {
        this.getAllAdminList()
        return
      }
      if (this.pageType === 'pmAdminUser') {
        this.getPmAllAdminList()
        return
      }
      if (this.pageType === 'analyseDepartUser') {
        if (type === 1) {
          this.pageNum = 1
        }
        this.getAnalyseDepartUserList(type)
        return
      }
      if (this.getDataState) {
        if (type === 1) {
          this.pageNum = 1
        }
        const _obj = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
          businessScope: this.businessScope, // 0: 全部人员 1:推荐人员
          userScope: this.userScope
        }
        if (this.pageType === 'taskSummary') {
          _obj.searchType = 1 // 不包含自己
        } else {
          _obj.searchType = 0 // 包含自己
        }
        this.$apis
          .QUERYALLMEMBERSINCOMPANYV2('', _obj)
          .then((rest) => {
            if (rest.status === 200) {
              if (type === 1) {
                this.userList = []
                this.list = []
              }
              this.total = rest.data.total
              const list = rest.data.rows
              if (this.pageNum === 1) {
                this.userList = []
                this.list = []
              }
              list.forEach((item) => {
                this.userList.push(item)
                this.list.push(item)
              })
              this.pageNum = this.pageNum + 1
            } else {
              this.$message.error(rest.message)
            }
            this.getDataState = true
          })
          .catch((err) => {
            console.log(err)
          })
        }
      this.getDataState = false
    },
    getAnalyseDepartUserList(type) {
      const _obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        departListId: this.departListId
      }
      this.$apis
        .ADMINDEPARTMENTUSERLIST(_obj)
        .then((rest) => {
          if (rest.status === 200) {
            if (type === 1) {
              this.userList = []
              this.list = []
            }
            this.total = rest.data.total
            const list = rest.data.rows
            list.forEach((item) => {
              item.name = item.userName
              item.username = item.userName
              this.userList.push(item)
              this.list.push(item)
            })
            this.pageNum = this.pageNum + 1
          } else {
            this.$message.error(rest.message)
          }
          this.getDataState = true
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取所有管理员
    getAllAdminList() {
      this.$apis.ADMINGETADMINLIST().then((res) => {
        if (res.status === 200) {
          this.total = res.data.length
          this.list = res.data || []
        }
      })
    },
    // 获取项目所有管理员
    getPmAllAdminList() {
      var obj_ = {
        projectId: this.projectId
      }
      this.$apis.PROPEOPLELIST(obj_).then((res) => {
        if (res.status === 200) {
          var adminUser = []
          res.data.forEach((item) => {
            if (item.isAdmin > 0) {
              item.username = item.name
              adminUser.push(item)
            }
          })
          this.total = adminUser.length
          this.list = adminUser
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    // 获取企业成员列表
    init2() {
      console.log("xxxxx");
      //this.name = ''
      const _obj = {}
      if (this.pageType === 'taskSummary') {
        _obj.name = ''
        _obj.searchType = 1 // 不包含自己
      } else {
        _obj.name = ''
        _obj.searchType = 0 // 包含自己
      }
      if (this.pageType === 'workHours') {
        const params = {
          taskId: this.taskId
        }
        this.$apis
          .WORKHOURSTASKALLUSER(params)
          .then((rest) => {
            const list = rest.data
            this.userList = list
            this.list=list
            this.total = rest.data.length
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.$apis
          .QUERYALLMEMBERSINCOMPANY('', _obj)
          .then((rest) => {
            const list = rest.data
            this.userList = list
            this.list=list
            this.total = rest.data.length
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 用户选择
    pick(row) {
      this.$emit('pick', row)
      this.$refs.userPickerPop.doClose()
      this.$emit('update:visible', false)
    },
    departAdd() {
      this.departUserVisible = true
    },
    chargeUserPicker(list) {
      if (list.length > 0) {
        list[0].id = list[0].id || list[0].userId
        list[0].userId = list[0].id || list[0].userId
        this.pick(list[0])
      }
    }
  }
}
</script>
<style lang="less" scoped>
.list-box {
  display: flex;
  flex-direction: column;
  .list-box-wrap {
    flex: 1;
    overflow-y: auto;
    .p-l-10 {
      line-height: 24px;
    }
    .departName {
      // margin: 0px 28px 0px 8px !important;
      width: 100%;
      max-width: 100%;
      margin: 0px;
      span {
        margin-right: 8px;
      }
    }
  }
}
</style>
