<template>
  <div @click.stop>
    <el-popover
      ref="userPickerCheckedPop"
      popper-class="user-picker-atdialog-pop"
      :visible-arrow="false"
      placement="bottom-start"
      :disabled="disabled"
      @show="init('show')"
      @hide="hide"
    >
      <div class="user-picker-out">
        <div
          v-if="pageFrom !== 'kpiNotAdjustUser' && pageFrom !== 'kpiV2'"
          class="search-box flex-new"
        >
          <!-- @input="init('input')" -->
          <el-input
            v-model="name"
            prefix-icon="el-icon-search"
            placeholder="搜索成员"
            class="user-el-input"
            @input="input"
          />
        </div>
        <div v-if="checked" class="select-people-num" @click.stop="selectPeopleClick()">
          <div v-if="!selectPeopleState" class="left">已选{{ currentPeopleList.length }}位</div>
          <div v-else class="select-people-wrap">
            <Icon icon-class="icon-left" class="arrow-icon" />返回
          </div>
          <div v-if="!selectPeopleState" class="select-people-wrap">
            <Icon icon-class="icon-right" class="arrow-icon" />
          </div>
        </div>
        <div class="people-title">成员</div>
        <div class="list-box">
          <div v-scroll-bottom="$loadMoreList" class="list-box-wrap">
            <template v-if="!selectPeopleState">
              <!-- :key="item.id" -->
              <div
                v-for="(item, index) in list"
                class="list-item"
                @click.stop="pickClick(item, index)"
              >
                <el-image
                  :src="
                    item.avatar || require('@/assets/imgs/common/avatar.png')
                  "
                  class="avatar-img person-img border-radius-20"
                  fit="cover"
                />
                <div class="name-wrap">
                  <div class="p-l-10">
                    <wx-open-data
                      :id="item.username"
                      :name-data="{ name: item.name ? item.name : item.userName }"
                    />
                    {{ item.userState == 4 ? '(离职)' : '' }}
                  </div>
                  <div class="departName" v-if="item.departName || item.jobTitle">
                    <span>
                      <DepartUserList
                        :departName="item.departName"
                        :departUserList="item.departUserList"
                      ></DepartUserList>
                    </span>
                    {{item.jobTitle}}
                  </div>
                </div>
                <div v-if="item.checked" class="imgSelect">
                  <i class="el-icon-check task-svg-icon"></i>
                </div>
              </div>
            </template>
            <template v-else>
              <!-- :key="item.id" -->
              <div
                v-for="(item, index) in selectList"
                :key="item.id || item.userId"
                class="list-item"
                @click.stop="pickClick(item, index)"
              >
                <el-image
                  :src="
                    item.avatar || require('@/assets/imgs/common/avatar.png')
                  "
                  class="avatar-img person-img border-radius-20"
                  fit="cover"
                />
                <div class="name-wrap">
                  <div class="p-l-10">
                    <wx-open-data
                      :id="item.username || '' || item.name"
                      :name-data="{ name: item.userName || '' || item.name }"
                    />
                    {{ item.userState == 4 ? '(离职)' : '' }}
                  </div>
                  <div class="departName" v-if="item.departName || item.jobTitle">
                    <span>
                      <DepartUserList
                        :departName="item.departName"
                        :departUserList="item.departUserList"
                      ></DepartUserList>
                    </span>
                    {{item.jobTitle}}
                  </div>
                </div>
                <div v-if="item.checked" class="imgSelect">
                  <i class="el-icon-check task-svg-icon"></i>
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="pageFrom !== 'kpiNotAdjustUser' && pageFrom !== 'kpiV2'"
            class="list-item peopeAdd"
            @click.stop="departAdd()"
          >
            <Icon icon-class="icon-a-tianjia2" class="avatar-img" />
            <div class="p-l-10">按组织选择</div>
          </div>
        </div>
        <div class="confirm-btn" @click.stop="confirmClick()">确定</div>
      </div>
      <slot slot="reference"></slot>
    </el-popover>
    <invite-by-code v-if="closeInviterCodeBoolean === true" @closeInviterCode="closeInviterCode" />
    <!-- 按组织选择 -->
    <depart-picker
      v-if="departUserVisible"
      v-model="departUserVisible"
      :can-delete="true"
      type="departPerson"
      :selected-person="currentPeopleList"
      :is-include-me="pageType == 'taskSummary' ? 1 : 0"
      :page-from="pageFrom"
      :created-user="createdUser"
      :limit="length"
      :userScope="userScope"
      @saveResult="chargeUserPicker"
    />
  </div>
</template>
<script>
import debounce from 'lodash/debounce' // 局部引入防抖
export default {
  props: {
    parentId: {}, // 上级人员id
    // 目标创建人id
    createdUser: {
      type: String,
      default: ''
    },
    fieldItem: {
      type: Object
    },
    pageType: {
      type: String,
      required: false,
      default: ''
    },
    selectPeopleList: {
      // 传入以前选择的成员
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    selectPeopleType: {
      // 1:传入值为数组对象id 2:传入值为数据用户id
      type: Number,
      required: false,
      default: 1
    },
    checked: {
      // true 多选
      type: Boolean,
      required: false,
      default: true
    },
    length: {
      // 选择人员的最大长度
      type: Number,
      required: false,
      default: 0
    },
    pageFrom: {
      type: String,
      required: false,
      default: ''
    },
    quarterId: {
      type: String,
      required: false,
      default: localStorage.getItem('quarterId')
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterUsers: {
      type: Array,
      default: () => {
        return []
      }
    },
    examineId: {
      default: ''
    },
    userScope: { // -1:过滤非正常人
      default: ''
    }
  },
  data() {
    return {
      selectPeopleState: false,
      userList: [],
      list: [],
      selectList: [],
      currentPeopleList: [],
      closeInviterCodeBoolean: false,
      invitationBoolean: false, // 邀请
      name: null, // 员工姓名
      pageNum: 1,
      pageSize: 20,
      total: 0,
      getDataState: true,
      departUserVisible: false,
      businessScope: 1
    }
  },
  computed: {},
  watch: {
    quarterId: function (val) {
      this.init('show')
    },
    filterUsers: {
      handler(val) {
        if (this.filterUsers.length > 0) {
          let list = JSON.parse(JSON.stringify(this.userList))
          list = list.filter((user) => {
            const exit = this.filterUsers.some((filterUser) => {
              return user.id === filterUser.receiverUserId
            })
            return !exit
          })
          this.list = list
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // 初始化
    // this.init()
  },
  destroyed() {
    this.$store.commit('common/setScrollBgVisible', false)
  },
  methods: {
    // 初始化
    input: debounce(function () {
      this.businessScope = 0
      this.init('input')
    }, 200),
    init(type) {
      if (type === 'show') {
        this.name = ''
        this.currentPeopleList = []
        this.$store.commit('common/setScrollBgVisible', true)
      }
      this.pageNum = 1
      this.userList = []
      this.list = []
      this.selectPeopleState = false
      if (this.getDataState) {
        if (this.pageFrom === 'meeting') {
          // 获取所有成员列表
          this.getCycleUserList(this.pageType, type === 'show' ? 1 : '')
        } else if (this.pageFrom === 'kpiNotAdjustUser') {
          // 获取绩效成员列表
          this.getKpiUserList(this.pageType, type === 'show' ? 1 : '')
        } else if (this.pageFrom == 'kpiV2') {
          // 新版绩效 underlings-获取下级人员列表
          if (this.pageType == 'underlings') {
            this.getUnderlingsUserList(this.pageType, type === 'show' ? 1 : '')
          }
        } else {
          // 获取所有成员列表
          this.getUserList(this.pageType, type === 'show' ? 1 : '')
        }
      }
      // this.getDataState = false
      this.userVisible = true
    },
    // 获取下级人员列表
    getUnderlingsUserList(pageType, type) {
      const params = {
        userId: this.parentId,
        companyId: this.userInfo.companyId
      }
      this.$apis.UNDERLINGSUSERLIST(params).then((res) => {
        if (res.status === 200) {
          console.log('下级人员列表---', res.data)
          this.userList = this.userList.concat(res.data)
          this.list = this.list.concat(res.data)
          this.selectList = JSON.parse(JSON.stringify(this.selectPeopleList))
          if (this.selectPeopleList.length > 0) {
            this.userListCheck(1, type)
          }
          // this.getDataState = true
        } else {
          this.$message({
            msg: res.message,
            type: 'error'
          })
        }
      })
    },
    $loadMoreList() {
      if (
        parseInt(this.list.length) < parseInt(this.total) &&
        this.getDataState &&
        !this.selectPeopleState
      ) {
        if (this.pageFrom === 'meeting') {
          // 获取所有成员列表
          this.getCycleUserList(this.pageType, '')
        } else {
          // 获取所有成员列表
          this.getUserList(this.pageType, '')
        }
        // this.getDataState = false
      }
    },
    // 获取企业成员列表
    async getKpiUserList(events, type) {
      const _obj = {
        examineId: this.examineId
      }
      await this.$apis
        .EXAMINEUSERLISTV2(_obj)
        .then((rest) => {
          this.userList = this.userList.concat(rest.data)
          this.list = this.list.concat(rest.data)
          this.selectList = JSON.parse(JSON.stringify(this.selectPeopleList))
          if (this.selectPeopleList.length > 0) {
            this.userListCheck(1, type)
          }
          this.getDataState = true
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取企业成员列表
    async getUserList(events, type) {
      if (this.pageNum === 1) {
        this.userList = []
        this.list = []
        this.total = 0
      }
      const _obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name: this.name,
        businessScope: this.businessScope,
        userScope: this.userScope
      }
      if (events === 'taskSummary') {
        _obj.searchType = 1 // 不包含自己
      } else {
        _obj.searchType = 0 // 包含自己
      }
      await this.$apis
        .QUERYALLMEMBERSINCOMPANYV2('', _obj)
        .then((rest) => {
          if (this.pageNum === 1) {
            this.userList = []
            this.list = []
          }
          const list = rest.data.rows
          let filterList = JSON.parse(JSON.stringify(rest.data.rows))
          if (this.filterUsers.length > 0) {
            filterList = filterList.filter((user) => {
              const exit = this.filterUsers.some((filterUser) => {
                return user.id === filterUser.receiverUserId
              })
              return !exit
            })
          }
          this.userList = this.userList.concat(list)
          this.list = this.list.concat(filterList)
          this.selectList = JSON.parse(JSON.stringify(this.selectPeopleList))
          this.total = rest.data.total
          this.pageNum = this.pageNum + 1
          if (this.selectPeopleList.length > 0) {
            this.userListCheck(1, type)
          }
          if (type !== 1) {
            this.userListCheck(2)
          }
          this.getDataState = true
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 获取周期内成员列表
    async getCycleUserList(events, type) {
      if (this.pageNum === 1) {
        this.userList = []
        this.list = []
        this.total = 0
      }
      const _obj = {}
      if (events === 'taskSummary') {
        _obj.name = ''
        _obj.searchType = 1 // 不包含自己
      } else {
        _obj.name = ''
        _obj.searchType = 0 // 包含自己
      }
      await this.$apis
        .CYCLEATMEMBER(this.quarterId, {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userName: this.name,
          cycleInfoId: this.quarterId,
          userScope: this.userScope
        })
        .then((rest) => {
          var currentList = []
          const list = rest.data.rows
          for (let i = 0; i < list.length; i++) {
            if (!this.$isQw) {
              list[i].name = list[i].userName
              list[i].avatar = list[i].userAvatar
              list[i].id = list[i].userId
            }

            if (
              events !== 'taskSummary' ||
              (events === 'taskSummary' && list[i].id !== this.userInfo.id)
            ) {
              currentList.push(list[i])
            }
          }
          if (this.pageNum === 1) {
            this.userList = []
            this.list = []
          }
          this.userList = this.list.concat(currentList)
          this.list = this.list.concat(currentList)
          this.selectList = this.selectPeopleList
          this.total = rest.data.total
          this.pageNum = this.pageNum + 1
          if (this.selectPeopleList.length > 0) {
            this.userListCheck(1, type)
          }
          if (type !== 1) {
            this.userListCheck(2)
          }
          this.getDataState = true
        })
        .catch((err) => {
          console.log(err)
        })
    },
    selectPeopleClick() {
      if (!this.selectPeopleState) {
        if (this.currentPeopleList.length === 0) {
          this.$message.warn('请选择人员哦！')
          return
        }
      }
      // 恢复全部成员
      // this.name = ''
      this.list = this.userList
      this.selectList = JSON.parse(JSON.stringify(this.currentPeopleList))
      this.selectPeopleState = !this.selectPeopleState
      this.userListCheck(2)
    },
    // 用户选择
    pickClick(row, index) {
      // type 1:用户列表  2:用户选择列表
      if (row.checked) {
        this.currentPeopleList.forEach((items, indexs) => {
          console.log(222, items)
          items.id = items.id || items.attendUserId
          row.id = row.id || row.attendUserId
          if (items.id === row.id) {
            if (this.pageFrom === 'meeting' && row.type === 1) {
              this.$message.warn('会议发起人不能删除哦！')
            } else if (
              this.pageFrom === 'okrShare' &&
              this.createdUser == items.id
            ) {
              this.$message.warn('目标创建人不能删除哦！')
            } else if (
              this.pageFrom === 'conclusionPerMan' &&
              this.createdUser == items.id
            ) {
              this.$message.warn('模版创建人不能删除哦！')
            } else if (this.pageType === 'taskSummary' && items.default) {
              this.$message.warn('不可以删除默认接收人哦！')
            } else {
              this.currentPeopleList.splice(indexs, 1)
            }
          }
        })
        this.currentPeopleList = this.$utils.distincts(this.currentPeopleList)
        this.selectList = this.$utils.distincts(this.currentPeopleList)
        // this.selectPeopleList = JSON.parse(JSON.stringify(this.$utils.distincts(this.currentPeopleList)))
        var list = this.list
        list.forEach((itemf, indexf) => {
          this.list[indexf].checked = false
        })
        this.list = list
        this.userListCheck(2) // 列表用户选中状态更新
      } else {
        console.log(333, row)
        if (this.length > 0 && this.currentPeopleList.length >= this.length) {
          this.$message.warn('当前选择人员已超出最大长度哦')
          return
        }
        if (this.pageFrom === 'meeting') {
          row.attendUserId = row.id
        }

        this.currentPeopleList.push(row)
        this.currentPeopleList = this.$utils.distincts(this.currentPeopleList)
        this.selectList = this.$utils.distincts(this.currentPeopleList)
        // this.selectPeopleList = JSON.parse(JSON.stringify(this.$utils.distincts(this.currentPeopleList)))
        this.userListCheck(2) // 列表用户选中状态更新
      }
    },
    confirmClick(row, type, list) {
      // if (this.pageFrom === 'kpiV2' && this.pageType === 'underlings') {
      this.currentPeopleList.forEach((item) => {
        item.id = item.id || item.userId
        item.userId = item.id || item.userId
      })
      // }
      this.$emit(
        'pick',
        type === 'depart' ? list : this.currentPeopleList,
        this.fieldItem
      )
      if (this.$refs.userPickerCheckedPop) {
        this.$refs.userPickerCheckedPop.doClose()
      }
      this.$emit('update:visible', false)
      this.hide()
      this.currentPeopleList = []
      this.selectPeopleList = []
    },
    hide() {
      console.log('hide')
      this.$store.commit('common/setScrollBgVisible', false)
      if (this.userVisible) {
        if (this.$refs.userPickerCheckedPop) {
          this.$refs.userPickerCheckedPop.doClose()
        }
      }
      this.userVisible = false
    },
    userListCheck(type, showState) {
      if (type === 1) {
        // 初始化
        var selectPeopleListNew = []
        this.list.forEach((itemf, indexf) => {
          this.selectPeopleList.forEach((items, indexs) => {
            if (this.selectPeopleType === 2) {
              if (itemf.id === items || itemf.checked) {
                if (!itemf.checked) {
                  selectPeopleListNew.push(itemf)
                }
                this.list[indexf].checked = true
                if (itemf.id === items) {
                  items.departName = itemf.departName
                  items.jobTitle = itemf.jobTitle
                  items.departUserList = itemf.departUserList
                }
              } else {
                this.list[indexf].checked = false
              }
            } else {
              if (this.pageFrom === 'meeting') {
                items.id = items.attendUserId
              }
              if (itemf.id === items.id || itemf.checked) {
                if (!itemf.checked) {
                  if (this.pageFrom === 'meeting') {
                    itemf.type = items.type
                  }
                  selectPeopleListNew.push(itemf)
                }
                // 汇报默认接收人
                if (
                  this.pageType === 'taskSummary' &&
                  items.default &&
                  itemf.id === items.id
                ) {
                  this.list[indexf].default = true
                }
                if (itemf.id === items.id) {
                  items.departName = itemf.departName
                  items.jobTitle = itemf.jobTitle
                  items.departUserList = itemf.departUserList
                }
                this.list[indexf].checked = true
              } else {
                this.list[indexf].checked = false
              }
            }
          })
        })
        // this.$nextTick(() => {
        // this.currentPeopleList = selectPeopleListNew
        if (showState === 1) {
          this.currentPeopleList = JSON.parse(
            JSON.stringify(selectPeopleListNew)
          )
        }
        if (showState) {
          this.currentPeopleList = JSON.parse(
            JSON.stringify(this.selectPeopleList)
          )
        }
        // })
      } else {
        var selectList = []
        if (this.selectPeopleState) {
          selectList = this.selectList
        } else {
          selectList = this.list
        }
        selectList.forEach((itemf, indexf) => {
          this.currentPeopleList.forEach((items, indexs) => {
            if (itemf.id === items.id || itemf.checked) {
              selectList[indexf].checked = true
            } else {
              selectList[indexf].checked = false
            }
          })
        })
      }
    },
    // 关闭邀请二维验证码
    closeInviterCode() {
      this.closeInviterCodeBoolean = false
    },
    invitationFunction() {
      this.closeInviterCodeBoolean = !this.closeInviterCodeBoolean
      this.invitationBoolean = !this.invitationBoolean
    },
    departAdd() {
      this.departUserVisible = true
    },
    chargeUserPicker(list) {
      list.forEach((item) => {
        item.id = item.id || item.userId
        item.userId = item.id || item.userId
      })
      this.confirmClick('', 'depart', list)
    }
  }
}
</script>

<style lang="less" scoped>
.list-box {
  display: flex;
  flex-direction: column;
  .list-box-wrap {
    flex: 1;
    overflow-y: auto;
    .p-l-10 {
      line-height: 24px;
    }
    .departName {
      // margin: 0px 28px 0px 8px !important;
      width: 100%;
      max-width: 100%;
      margin: 0px;
      span {
        margin-right: 8px;
      }
    }
  }
}
.person-img {
  min-width: 30px;
}
.task-svg-icon {
  font-size: 18px;
  width: 18px;
  height: 18px;
  margin-left: 10px;
}
.confirm-btn {
  margin-top: 16px;
  height: 40px;
  line-height: 40px;
  background: #005eff;
  border-radius: 16px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #3a78f6;
  }
}
.select-people-num {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  .left {
    color: #14142b;
    font-size: 14px;
  }

  .select-people-wrap {
    color: #6e7491;
    display: flex;
    align-items: center;
    .arrow-icon {
      font-size: 20px;
      margin-right: 2px;
    }
  }
}
.people-title {
  font-size: 14px;
  color: #a0a3bd;
  line-height: 20px;
  margin-top: 8px;
}
.imgSelect {
  i {
    color: #3a78f6;
  }
}
</style>
