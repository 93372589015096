<template>
  <span style="display: inline;">
    <ww-open-data v-if="$isQw" :type="type == 1 ? 'userName' : 'departmentName'" :openid="id" />
    <template v-else>{{nameData&&nameData.name}}</template>
  </span>
</template>
<script>
import wxJs from '@/utils/mixins/wxJs'
export default {
  mixins: [wxJs],
  name: 'Login',
  data() {
    return {
      isQw:false
    }
  },
  props: {
    // 类型：分为部门和人员姓名 1:姓名 2:部门
    type: {
      default: 1
    },
    // id:人员或部门的ID
    id: {
      type: String,
      default: ''
    },
    // 数据对象
			nameData:{
				type: Object,
				default: function(){
					return {}
				}
			}
  },
  created() {
    if (this.$isQw) {
      this.showData()
    }
    this.isQw =this.$isQw
  },
  mounted() {
  },
  methods: {
    showData() {
      var that = this
      /* eslint-disable-next-line */
      // if (WWOpenData.checkSession) {
      //   /* eslint-disable-next-line */
      //   WWOpenData.checkSession({
      //     success(res) {
      //       // that.$message.success('open-data 登录态校验成功')
      //       console.log('open-data 登录态校验成功')
      //     },
      //     fail(res) {
      //       // that.$message.error('open-data 登录态过期')
      //       that.getJsapiTicketAgent() // 重新获取签名
      //     }
      //   })
      // }
      /* eslint-disable-next-line */
      if (WWOpenData.on) {
        /**
         * ww-open-data 元素数据发生变更时触发
         */
        /* eslint-disable-next-line */
        // WWOpenData.on('update', event => {
        //   const openid = event.detail.element.getAttribute('openid')
        //   console.info('渲染数据发生变更', openid, event.detail.hasData)
        // })
        /* eslint-disable-next-line */
        WWOpenData.on('sessionTimeout', () => {
          // that.$message.error('获取数据失败')
          that.$message.error('登录态过期')
          that.getJsapiTicketAgent() // 重新获取签名
        })
        /**
         * ww-open-data 获取数据失败时触发
         */
        /* eslint-disable-next-line */
        // WWOpenData.on('error', () => {
        //   // that.$message.error('获取数据失败')
        //   console.log('WWOpenData获取数据失败')
        // })
      }
      /* eslint-disable-next-line */
      // WWOpenData.bind(document.querySelector('ww-open-data'))
      WWOpenData.bind(this.$el)
    }
  }
}
</script>
